import { createStore } from "redux";
import {
  SET_ACTIVE_STATE,
  SET_TITLE,
  TOGGLE_DRAWER,
  TOGGLE_SIDE_NAV,
} from "./helper/constants";

const initialState = {
  isOpenByOwner: false,
  drawerOpen: false,
  sideNavOpen: true,
  getCounters: false,
  unseenNewsCount: 0,
  unseenChatCount: 0,
  unseenBuildingChatCount: 0,
  unseenExternalProject: 0,
  unseenInternalProject: 0,
  user: null,
  userData: [],
  title: "User Dashboard",
  setting: null,
  loader: false,
  projects: [],
  internalProjectsAll: [],
  openProjects: [],
  updateProjectActionFire: false,
  article: null,
  toggleWorkArea: true,
  rightTop: "project1",
  rightBottom: "chat2",
  groups: [],
  groupsBottom: [],
  openChats: [],
  openBottomChats: [],
  openBottomProjects: [],
  updateBottomProjectActionFire: false,
  projectRequestCount: 0,
  forwardMessage: false,
  messageIds: [],
  updateGroupMessage: {},
  language: null,
  groupChats: [],
  forwardGroupMessages: {},
  draftMessages: [],
  unSeenAllCount: {},
  AllGroupsOfProjects: [],
  joinRequestsInternal: [],
  joinRequestsExternal: [],
  isExternalSelected: 0,
  companyImage: null,
  selectedCompanyId: null,
  resetSocket: false,
  forOpenChatId: null,
  ViewerImages: [],
  inActivity: false,
  activityMessages: [],
  activityMessagesReminders: [],
  isNewMessage: false,
  resetProjectMarking: false,
  initalIndex: 0,
  isAutoTranslation: false,
  userLoginToken: null,
  isUnauthorized: false,
  isJitsiCallStarted: false,
  joinNowRoomWise:[]
};
export const IS_OPEN_BY_OWNER = Symbol("IS_OPEN_BY_OWNER");
export const SET_IS_UNAUTHORIZED = Symbol("SET_IS_UNAUTHORIZED");
export const SET_USER = Symbol("SET_USER");
export const SET_USER_LOGIN_TOKEN = Symbol("SET_USER_LOGIN_TOKEN");
export const SET_PROFILE = Symbol("SET_PROFILE");
export const SET_SETTING = Symbol("SET_SETTING");
export const SET_LOADER = Symbol("SET_LOADING");
export const PROJECTS = Symbol("PROJECTS");
export const SET_IN_ACTIVITY = Symbol("SET_IN_ACTIVITY");
export const SET_ALLGROUPS_PROJECTS = Symbol("PROJECTS");
export const INTERNAL_PROJECTS = Symbol("INTERNAL_PROJECTS");
export const OPEN_PROJECTS = Symbol("OPEN_PROJECTS");
export const UPDATE_PROJECT_ACTION_FIRE = Symbol("UPDATE_PROJECT_ACTION_FIRE");
export const OPEN_BOTTOM_PROJECTS = Symbol("OPEN_BOTTOM_PROJECTS");
export const UPDATE_BOTTOM_PROJECT_ACTION_FIRE = Symbol(
  "UPDATE_BOTTOM_PROJECT_ACTION_FIRE"
);
export const ARTICLE = Symbol("ARTICLE");
export const RESET = Symbol("RESET");
export const TOGGLE_WORK_AREA = Symbol("TOGGLE_WORK_AREA");
export const RIGHT_TOP = Symbol("RIGHT_TOP");
export const RIGHT_BOTTOM = Symbol("RIGHT_BOTTOM");
export const GROUPS = Symbol("GROUPS");
export const OPEN_CHATS = Symbol("OPEN_CHATS");
export const OPEN_BOTTOM_CHATS = Symbol("OPEN_BOTTOM_CHATS");
export const GROUPS_BOTTOM = Symbol("GROUPS_BOTTOM");
export const NEWS_BADGE_COUNT = Symbol("NEWS_BADGE_COUNT");
export const ALL_BADGE_COUNT = Symbol("ALL_BADGE_COUNT");
export const CHAT_BADGE_COUNT = Symbol("CHAT_BADGE_COUNT");
export const PROJECT_BADGE_COUNT = Symbol("PROJECT_BADGE_COUNT");
export const UNSEEN_EXTERNAL_BADGE = Symbol("UNSEEN_EXTERNAL_BADGE");
export const UNSEEN_INTERNAL_BADGE = Symbol("UNSEEN_INTERNAL_BADGE");
export const GET_COUNTERS = Symbol("GET_COUNTERS");
export const SET_JOIN_REQUEST_INTERNAL = Symbol("SET_JOIN_REQUEST_INTERNAL");
export const SET_JOIN_REQUEST_EXTERNAL = Symbol("SET_JOIN_REQUEST_EXTERNAL");
export const START_JITSI_CALL = Symbol("START_JITSI_CALL");
export const JOIN_ROOM_BUTTON_STATUS_ROOM_WISE = Symbol("JOIN_ROOM_BUTTON_STATUS_ROOM_WISE");







export const PROJECT_REQUEST_COUNT = Symbol("PROJECT_REQUEST_COUNT");
export const FORWARD_MESSAGE = Symbol("FORWARD_MESSAGE");
export const MESSAGE_IDS = Symbol("MESSAGE_IDS");
export const FOR_OPEN_CHAT_ID = Symbol("FOR_OPEN_CHAT_ID");
export const UPDATE_GROUP_MESSAGE = Symbol("UPDATE_GROUP_MESSAGE");
export const LANGUAGE = Symbol("LANGUAGE");
export const GROUP_CHATS = Symbol("GROUP_CHATS");
export const FORWARD_GROUP_MESSAGES = Symbol("FORWARD_GROUP_MESSAGES");
export const DRAFT_MESSAGES = Symbol("DRAFT_MESSAGES");
export const SET_TAB = Symbol("SET_TAB");
export const SET_COMPANY_IMAGE = Symbol("SET_COMPANY_IMAGE");
export const SET_COMPANY_ID = Symbol("SET_COMPANY_ID");
export const RESET_SOCKET = Symbol("RESET_SOCKET");
export const SET_VIEWER_IMAGES = Symbol("SET_VIEWER_IMAGES");
export const SET_VIEWER_IINITAL_INDEX = Symbol("SET_VIEWER_IINITAL_INDEX");
export const SET_ACTIVITY_MESSAGES = Symbol("SET_ACTIVITY_MESSAGES");
export const SET_ACTIVITY_MESSAGES_REMINDERS = Symbol("SET_ACTIVITY_MESSAGES_REMINDERS");
export const IS_NEW_MESSAGE = Symbol("IS_NEW_MESSAGE");
export const RESET_MARKIN_PROJECT = Symbol("RESET_MARKIN_PROJECT");
export const SET_AUTO_TRANSLATION = Symbol("SET_AUTO_TRANSLATION");









export const store = createStore((state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case SET_IS_UNAUTHORIZED:
      return { ...state, isUnauthorized: action.status };
    case SET_USER_LOGIN_TOKEN:
      return { ...state, userLoginToken: action.status };
    case RESET_MARKIN_PROJECT:
      return { ...state, resetProjectMarking: action.status };
    case SET_AUTO_TRANSLATION:
      return { ...state, isAutoTranslation: action.status };
    case SET_ACTIVITY_MESSAGES_REMINDERS:
      return { ...state, activityMessagesReminders: action.messages };
    case IS_OPEN_BY_OWNER:
      return { ...state, isOpenByOwner: action.open };
    case SET_ACTIVITY_MESSAGES:
      return { ...state, activityMessages: action.messages };
    case IS_NEW_MESSAGE:
      return { ...state, isNewMessage: action.status };
    case FOR_OPEN_CHAT_ID:
      return { ...state, forOpenChatId: action.id };
    case RESET_SOCKET:
      return { ...state, resetSocket: action.payload };
    case SET_COMPANY_IMAGE:
      return { ...state, companyImage: action.payload };
    case SET_COMPANY_ID:
      return { ...state, selectedCompanyId: action.payload };
    case SET_TAB:
      return { ...state, isExternalSelected: action.status };
    case TOGGLE_DRAWER:
      return { ...state, drawerOpen: action.open };
    case TOGGLE_SIDE_NAV:
      return { ...state, sideNavOpen: action.sideNavOpen };
    case START_JITSI_CALL:
      return { ...state, isJitsiCallStarted: action.payload };
    case SET_USER:
      return { ...state, user: action.user };
    case SET_PROFILE:
      return { ...state, profile: action.profile };
    case SET_SETTING:
      return { ...state, setting: action.setting };
    case SET_ACTIVE_STATE:
      return { ...state, activeState: action.activeState };
    case SET_TITLE:
      return { ...state, title: action.title };
    case SET_LOADER:
      return { ...state, loader: action.loader };
    case PROJECTS:
      return { ...state, projects: action.projects };
    case SET_ALLGROUPS_PROJECTS: {
      let projectGroups = [...state.AllGroupsOfProjects]
      let { projectId, newGroups } = action.payload
      let userIndex = projectGroups.findIndex(e => e.projectId == projectId)
      if (userIndex == -1) {
        let obj = {
          projectId: projectId,
          groups: [...newGroups],
        }
        projectGroups.push(obj)
      }
      else {
        projectGroups[userIndex]['groups'] = [...newGroups]
      }
      return {
        ...state,
        AllGroupsOfProjects: [...projectGroups],
      }
    };
    case INTERNAL_PROJECTS:
      return { ...state, internalProjectsAll: action.projects };
      case JOIN_ROOM_BUTTON_STATUS_ROOM_WISE:
      return { ...state, joinNowRoomWise: action.payload }; 
    case OPEN_PROJECTS:
      return { ...state, openProjects: action.openProjects };
    case UPDATE_PROJECT_ACTION_FIRE:
      return {
        ...state,
        updateProjectActionFire: action.updateProjectActionFire,
      };
    case OPEN_BOTTOM_PROJECTS:
      return { ...state, openBottomProjects: action.openBottomProjects };
    case UPDATE_BOTTOM_PROJECT_ACTION_FIRE:
      return {
        ...state,
        updateBottomProjectActionFire: action.updateBottomProjectActionFire,
      };
    case ARTICLE:
      return { ...state, article: action.article };
    case TOGGLE_WORK_AREA:
      return { ...state, toggleWorkArea: action.toggleWorkArea };
    case RIGHT_TOP:
      return { ...state, rightTop: action.rightTop };
    case RIGHT_BOTTOM:
      return { ...state, rightBottom: action.rightBottom };
    case GROUPS:
      return { ...state, groups: action.groups };
    case SET_JOIN_REQUEST_EXTERNAL:
      return { ...state, joinRequestsExternal: action.requests };
    case SET_JOIN_REQUEST_INTERNAL:
      return { ...state, joinRequestsInternal: action.requests };
    case GROUPS_BOTTOM:
      return { ...state, groupsBottom: action.groupsBottom };
    case OPEN_CHATS:
      return { ...state, openChats: action.openChats };
    case OPEN_BOTTOM_CHATS:
      return { ...state, openBottomChats: action.openBottomChats };
    case NEWS_BADGE_COUNT:
      return { ...state, unseenNewsCount: action.counters };
    case PROJECT_BADGE_COUNT:
      return { ...state, unseenBuildingChatCount: action.counters };
    case CHAT_BADGE_COUNT:
      return { ...state, unseenChatCount: action.counters };
    case UNSEEN_EXTERNAL_BADGE:
      return { ...state, unseenExternalProject: action.counters };
    case UNSEEN_INTERNAL_BADGE:
      return { ...state, unseenInternalProject: action.counters };
    case ALL_BADGE_COUNT: {
      let cnt = action.unSeenAllCount.unseenBuildingChatCount + action.unSeenAllCount.unseenChatCount
      if (window.electron && typeof window.electron.sendBadgeUpdate === 'function')
        window.electron.sendBadgeUpdate(cnt);
      return { ...state, unSeenAllCount: action.unSeenAllCount };
    }
    case PROJECT_REQUEST_COUNT:
      return { ...state, projectRequestCount: action.projectRequestCount };
    case FORWARD_MESSAGE:
      return { ...state, forwardMessage: action.forwardMessage };
    case FORWARD_GROUP_MESSAGES:
      return { ...state, forwardGroupMessages: action.forwardGroupMessages };
    case MESSAGE_IDS:
      return { ...state, messageIds: action.messageIds };
    case UPDATE_GROUP_MESSAGE:
      return { ...state, updateGroupMessage: action.updateGroupMessage };
    case LANGUAGE:
      return { ...state, language: action.language };
    case SET_IN_ACTIVITY:
      return { ...state, inActivity: action.status };
    case GROUP_CHATS:
      return { ...state, groupChats: action.groupChats };
    case DRAFT_MESSAGES:
      return { ...state, draftMessages: action.draftMessages };
    case SET_VIEWER_IMAGES:
      return { ...state, ViewerImages: action.ViewerImages };
    case SET_VIEWER_IINITAL_INDEX:
      return { ...state, initalIndex: action.index };
    case RESET:
      return initialState;
    default:
      return state;
  }
});


