import React from 'react';
import { JitsiMeeting } from '@jitsi/react-sdk';
import { useDispatch } from 'react-redux';
import { START_JITSI_CALL } from '../store';
import { callAPI } from '../helper/apiUtils';

const RCJitsiCall = ({ isJitsiCallStarted, user }) => {
    const { roomId } = isJitsiCallStarted;
    const [showLoader, setShowLoader] = React.useState(true);
    const dispatch = useDispatch();
    const displayName = `${user.firstName} ${user.lastName}`;

    const EndJitsiCall = async () => {
        const { data } = await callAPI(`/videochat/end`, {}, "POST", { room: roomId });
        console.log(data);
        dispatch({ type: START_JITSI_CALL, payload: false });
    }

    const handleApiReady = (api) => {
        setShowLoader(false);

        // Force disable pre-join page programmatically
        api.executeCommand('prejoinPageEnabled', false);

        api.addListener("participantJoined", (event) => {
            console.log("Participant joined:", event);
        });

        api.addListener("participantLeft", (event) => {
            console.log("Participant left:", event);
        });

        api.addListener("videoConferenceJoined", (event) => {
            console.log("Conference joined:", event);
        });

        api.addListener("videoConferenceLeft", (event) => {
            console.log("Conference left:", event);
            EndJitsiCall();
        });
    };

    return (
        <div style={{ position: "absolute", left: 0, right: 0, bottom: 0, top: 0, zIndex: 1000, background: '#111B22', display: "flex", flexDirection: "column" }}>
            {showLoader && (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )}
            <JitsiMeeting
                domain="jitsi.kjuup.de"
                roomName={roomId || ""}
                configOverwrite={{
                    startWithAudioMuted: true,
                    disableModeratorIndicator: true,
                    disableInviteFunctions: true,
                    startScreenSharing: false,
                    enableEmailInStats: false,
                    'prejoinConfig.enabled': false, // Disable the pre-join screen
                }}
                interfaceConfigOverwrite={{
                    DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                }}
                userInfo={{
                    displayName: displayName,
                }}
                onApiReady={handleApiReady}
                getIFrameRef={(iframeRef) => {
                    iframeRef.style.height = '100vh'; // Ensure iframe is always visible
                }}
            />
        </div>
    );
};

export default RCJitsiCall;
